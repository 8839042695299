import * as React from "react";
import { graphql } from "gatsby";
import { Link } from "gatsby-plugin-react-i18next";
import Styled from "styled-components/macro";
import Media from "@uizard-io/styled-media-query";
import Meta from "../../../components/meta";
import Layout from "../../../components/layout/Layout";
import TemplatesSection from "../../../components/pages/templates/TemplatesSection";
import Content from "../../../components/layout/Content/Content";
import Shoutout from "../../../components/pages/signup/Shoutout";

import gmdwaImage from "../../../components/pages/home/HomeExamples/GMDWA_cover.png";
import carbonImage from "../../../components/pages/home/HomeExamples/IBM_Carbon_cover.png";
import quickBiteImage from "../../../components/pages/home/HomeExamples/food-delivery-web-app-cover.png";
import cloudwebImage from "../../../components/pages/home/HomeExamples/cloud-sharing-web-app-cover.png";
import ecomdashImage from "../../../components/pages/home/HomeExamples/ecommerce-dashboards-context.png";
import ecomdashlightImage from "../../../components/pages/home/HomeExamples/ecom-dashboards-light-plp.png";
import todowebImage from "../../../components/pages/home/HomeExamples/to-do-web-app-cover.png";
import teammessagingwebImage from "../../../components/pages/home/HomeExamples/team-messaging-web-app-plp.png";
import videocallImage from "../../../components/pages/home/HomeExamples/video-call-web-app-cover.png";
import bankingwebwireframeImage from "../../../components/pages/home/HomeExamples/wireframe-online-banking-app-web-plp.png";
import projectwebImage from "../../../components/pages/home/HomeExamples/project-management-web-app-cover.png";
import streamingwebImage from "../../../components/pages/home/HomeExamples/music-streaming-web-app-cover.png";
import hrAppImage from "../../../components/pages/home/HomeExamples/hr_app_cover.png";
import hotelwebImage from "../../../components/pages/home/HomeExamples/hotel-booking-web-plp.png";
import crowdwebImage from "../../../components/pages/home/HomeExamples/crowdfunding-app-web-plp.png";
import weatherwebdarkImage from "../../../components/pages/home/HomeExamples/weather-web-dark-plp.png";
import weatherweblightImage from "../../../components/pages/home/HomeExamples/weather-web-light-plp.png";
import pitchDeckImage from "../../../components/pages/home/HomeExamples/online_interactive_pitch_deck_cover.png";
import onlineBankingImage from "../../../components/pages/home/HomeExamples/online_banking_web_app_cover.png";
import flightBookingWebAppImage from "../../../components/pages/home/HomeExamples/flight-booking-web-plp.png";
import portfolioWebAppImage from "../../../components/pages/home/HomeExamples/freelancer-portfolio-web-plp.png";
import groceryWebAppImage from "../../../components/pages/home/HomeExamples/grocery-store-web-plp.png";
import learningWebAppImage from "../../../components/pages/home/HomeExamples/learning-app-web-plp.png";


const ContentExtended = Styled(Content)`
  margin-top: 10rem;

  ${Media.greaterThan("medium")`
    margin-top: 16rem;
  `}
`;

const BreadcrumbContainer = Styled.div`
  margin-top: 10rem;

  ${Media.greaterThan("medium")`
    margin-top: 16rem;
  `}

    margin-bottom: 1rem;
`;

const BreadcrumbLink = Styled(Link)`
  font-size: 1.5rem;

  &:hover {
    color: ${(props) => props.theme.colors.black};
    text-decoration: underline;
  }
`;

const Breadcrumb = Styled.span`
  font-size: 1.5rem;
`;

const Chevron = Styled.span`
  position: relative;
  margin: 0 0.5rem;
  top: 0.35rem;
`;

const Templates = ({ data }) => (
  <Layout>
    <Meta
      title="Web App Templates | Web App Design Templates | Uizard"
      description="Start any web app project faster using Uizard's beautiful pre-made templates. Customize your design easily and quickly in just a few clicks."
      url="/templates/web-app-templates/"
    />

    <ContentExtended narrow>
      <BreadcrumbContainer>
        <BreadcrumbLink to="/templates/">Templates</BreadcrumbLink>
        <Chevron aria-hidden="true">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
          >
            <path d="M6.47 4.29l3.54 3.53c.1.1.1.26 0 .36L6.47 11.7a.75.75 0 1 0 1.06 1.06l3.54-3.53c.68-.69.68-1.8 0-2.48L7.53 3.23a.75.75 0 0 0-1.06 1.06z"></path>
          </svg>
        </Chevron>
        <Breadcrumb>Web App Templates</Breadcrumb>
      </BreadcrumbContainer>
    </ContentExtended>

    <TemplatesSection
      title="Web app templates"
      description="Start any web app project faster using Uizard's beautiful pre-made templates. Customize your design easily and quickly in just a few clicks. With a stunning range of free web app templates, you can start your web app design project with the click of a button. Explore our web app design templates now and kick off your product design."
      h1={true}
      thumbnails={[
        {
          image: quickBiteImage,
          alt: "food delivery website design template",
          title: "Food delivery web app",
          link: "/templates/web-app-templates/online-food-delivery/",
        },
        {
          image: pitchDeckImage,
          alt: "pitch deck website design template",
          title: "Interactive online pitch deck",
          link: "/templates/web-app-templates/interactive-online-pitch-deck/",
        },
        {
          image: onlineBankingImage,
          alt: "banking website design template",
          title: "Banking web app",
          link: "/templates/web-app-templates/online-banking-web-app/",
        },
        {
          image: bankingwebwireframeImage,
          alt: "banking website wireframe",
          title: "Banking web app wireframe",
          link: "/templates/web-app-templates/banking-web-app-wireframe/",
        },
        {
          image: cloudwebImage,
          alt: "cloud storage web app design template",
          title: "Cloud storage web app",
          link: "/templates/web-app-templates/cloud-storage-web-app/",
        },
        {
          image: crowdwebImage,
          alt: "crowdfunding web app design template",
          title: "Crowdfunding web app",
          link: "/templates/web-app-templates/crowdfunding-web-app/",
        },
        {
          image: ecomdashImage,
          alt: "ecommerce web app dashboard templates in dark mode",
          title: "E-commerce web dashboards (dark)",
          link: "/templates/web-app-templates/ecommerce-web-app-dashboards/",
        },
        {
          image: ecomdashlightImage,
          alt: "ecommerce web app dashboard templates in light mode",
          title: "E-commerce web dashboards (light)",
          link: "/templates/web-app-templates/ecommerce-web-app-dashboards-light/",
        },
        {
          image: flightBookingWebAppImage,
          alt: "flight booking web app template",
          title: "Flight booking web app",
          link: "/templates/web-app-templates/flight-ticket-web-app/",
        },
        {
          image: gmdwaImage,
          alt: "gmd website design template",
          title: "Google Material Design web app",
          link: "/templates/web-app-templates/google-material-design-web-app/",
        },
        {
          image: groceryWebAppImage,
          alt: "grocery delivery web app template",
          title: "Grocery delivery web app",
          link: "/templates/web-app-templates/grocery-delivery-web-app/",
        },
        {
          image: hotelwebImage,
          alt: "hotel booking web app project image",
          title: "Hotel booking web app",
          link: "/templates/web-app-templates/hotel-booking-web-app/",
        },
        {
          image: hrAppImage,
          alt: "human resources website design template",
          title: "Human resources web app",
          link: "/templates/web-app-templates/hr-web-app/",
        },
        {
          image: carbonImage,
          alt: "ibm carbon website design template",
          title: "IBM Carbon cloud dashboard",
          link: "/templates/web-app-templates/ibm-carbon-cloud-dashboard-web-app/",
        },
        {
          image: learningWebAppImage,
          alt: "learning web app template",
          title: "Learning web app",
          link: "/templates/web-app-templates/learning-web-app/",
        },
        {
          image: streamingwebImage,
          alt: "music streaming website design template",
          title: "Music streaming web app",
          link: "/templates/web-app-templates/music-streaming-web-app/",
        },
        {
          image: portfolioWebAppImage,
          alt: "portfolio website design template",
          title: "Portfolio web app",
          link: "/templates/web-app-templates/portfolio-web-app/",
        },
        {
          image: projectwebImage,
          alt: "project management website design template",
          title: "Project management web app",
          link: "/templates/web-app-templates/project-management-web-app/",
        },
        {
          image: teammessagingwebImage,
          alt: "team messaging website design template",
          title: "Team messaging web app",
          link: "/templates/web-app-templates/team-messaging-web-app/",
        },
        {
          image: todowebImage,
          alt: "to do list website design template",
          title: "To do list web app",
          link: "/templates/web-app-templates/to-do-web-app/",
        },
        {
          image: videocallImage,
          alt: "video calling website design template",
          title: "Video calling web app",
          link: "/templates/web-app-templates/video-messaging-web-app/",
        },
        {
          image: weatherwebdarkImage,
          alt: "weather website design template dark mode",
          title: "Weather web app (dark)",
          link: "/templates/web-app-templates/weather-web-app-dark/",
        },
        {
          image: weatherweblightImage,
          alt: "weather website design template light mode",
          title: "Weather web app (light)",
          link: "/templates/web-app-templates/weather-web-app-light/",
        },
      ]}
    />

    <Content>
      <Shoutout
        headline="Design a web app in minutes"
        description="No tutorial needed!"
        cta="Sign up for free"
        imageA={data.shoutoutImageA.childImageSharp}
      />
    </Content>
  </Layout>
);

export default Templates;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    shoutoutImageA: file(
      relativePath: { eq: "shoutout/shoutout-image-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1232, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
